<template>
  <div>
    <h2>{{ tarif.tier_title }}</h2>

    <v-row>
      <v-col cols="12" md="2">
        <v-text-field v-model="insertData.preis_alter" label="Alter" />
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field
          v-model.number="insertData.preis_value_1"
          type="number"
          label="Risiko1"
        />
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field
          v-model.number="insertData.preis_value_2"
          type="number"
          label="Risiko2"
      /></v-col>
      <v-col cols="12" md="2">
        <v-text-field
          v-model.number="insertData.preis_value_3"
          type="number"
          label="Risiko3"
      /></v-col>
      <v-col cols="12" md="2">
        <v-text-field
          v-model.number="insertData.preis_value_4"
          type="number"
          label="Risiko4"
      /></v-col>
      <v-col cols="12" md="2">
        <v-text-field
          v-model.number="insertData.preis_value_5"
          type="number"
          label="Risiko5"
      /></v-col>
      <v-col cols="12" md="2">
        <v-btn @click="insertPreis()"
          >save T:{{ insertData.preis_tarif_id }}</v-btn
        >
      </v-col>
    </v-row>

    <br />
    <v-row v-for="item in preise" :key="item.preis_id">
      <v-col cols="12" md="2">ID:{{ item.preis_id }}</v-col>
      <v-col cols="12" md="2">{{ item.preis_alter }} Jahre</v-col>
      <v-col cols="12" md="2">{{ item.preis_value_1 }} €</v-col>
      <v-col cols="12" md="2">{{ item.preis_value_2 }} €</v-col>
      <v-col cols="12" md="2">{{ item.preis_value_3 }} €</v-col>
      <v-col cols="12" md="2">{{ item.preis_value_4 }} €</v-col>
      <v-col cols="12" md="2">{{ item.preis_value_5 }} €</v-col>
    </v-row>
  </div>
</template>

<script>
import fieldMixin from "./../mixins/fieldMixin";
export default {
  name: "index-home",
  components: {},
  mixins: [fieldMixin],
  data: function () {
    return {
      tarif: false,
      preise: false,
      insertData: {
        preis_tarif_id: this.$route.params.id,
        preis_alter: 0,
        preis_value_1: 0,
        preis_value_2: 0,
        preis_value_3: 0,
        preis_value_4: 0,
        preis_value_5: 0,
      },
      origin: false,
      table: "preise",
      selectupdate: {
        type: "einzel",
        id: this.$route.params.id,
        number: "one",
      },
      select: {
        type: "einzel",
        id: this.$route.params.id,
        number: "one",
      },
      selectpreise: {
        type: "alle",
        id: this.$route.params.id,
        number: "array",
      },
    };
  },
  created() {
    this.getTarife();
    this.getPreise();
  },

  methods: {
    insertPreis() {
      this.$store.commit("start_loading_full");

      this.$store
        .dispatch("insertPreis", this.insertData)
        .then((resp) => {
          this.$store.commit("stop_loading_full");
          this.insertData.preis_alter = 0;
          this.insertData.preis_value_1 = 0;
          this.insertData.preis_value_2 = 0;
          this.insertData.preis_value_3 = 0;
          this.insertData.preis_value_4 = 0;
          this.insertData.preis_value_5 = 0;
          this.getPreise();
        })
        .catch((err) => {
          this.$log.error(err);
        });
    },
    getPreise() {
      this.$store
        .dispatch("getPreise", this.selectpreise)
        .then((resp) => {
          if (resp.data.preise) {
            this.preise = resp.data.preise;
          }
        })
        .catch((err) => {
          this.$log.error(err);
        });
    },
    getTarife() {
      this.$store
        .dispatch("getTarife", this.select)
        .then((resp) => {
          if (resp.data.tarife) {
            this.tarif = resp.data.tarife;
          }
        })
        .catch((err) => {
          this.$log.error(err);
        });
    },
  },
};
</script> 
